// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-bier-js": () => import("./../../../src/pages/bier.js" /* webpackChunkName: "component---src-pages-bier-js" */),
  "component---src-pages-blog-js": () => import("./../../../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-idee-js": () => import("./../../../src/pages/idee.js" /* webpackChunkName: "component---src-pages-idee-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projekte-js": () => import("./../../../src/pages/projekte.js" /* webpackChunkName: "component---src-pages-projekte-js" */),
  "component---src-pages-vertrieb-js": () => import("./../../../src/pages/vertrieb.js" /* webpackChunkName: "component---src-pages-vertrieb-js" */)
}

